'use client'
import React, { useEffect, useRef } from 'react';


const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ!@£$%^&*()_+{}[]abcdefghijklmnopqrstuvwxyz0123456789";

function scrambleText(element, originalText) {
  if (!element) return;

  let scrambledText = originalText.split("").map(() => chars[Math.floor(Math.random() * chars.length)]).join("");

  const interval = setInterval(() => {
    scrambledText = originalText.split("").map((char, i) => {
      return Math.random() > 0.5 ? char : chars[Math.floor(Math.random() * chars.length)];
    }).join("");
    element.textContent = scrambledText;
  }, 90);

  setTimeout(() => {
    clearInterval(interval);
    element.textContent = originalText;
  }, 1000);
}

const TextScramble = ({ text }) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      scrambleText(textRef.current, text);
    }
  }, [text]);

  return (
    <div ref={textRef}>
      {text}
    </div>
  );
};

export default TextScramble;
