import React from 'react';
import './Developer.css';


const Developer = () => {
   return <div className='Dev'>   
            <div className='Developer-main'>
               <div className='Developer-main_title'>
                 <h4>
                   Developer
                 </h4>
              </div>
              
              {/* <div className="dev-container">
                <div className="ws00">
                  <iframe src="https://abi-hanna.web.app/" title="Website in iframe"></iframe>
                </div>
                <div className="ws00">
                  <iframe src="https://global-chef.web.app/" title="Website in iframe"></iframe>
                </div>
                <div className="ws00">
                  <iframe src="https://dr-darwiche.web.app/" title="Website in iframe"></iframe>
                </div>
                <div className="ws00">
                  <iframe src="https://kay-day.web.app/" title="Website in iframe"></iframe>
                </div>
                <div className="ws00">
                  <iframe src="https://nippys-alcohol.web.app/" title="Website in iframe"></iframe>
                </div>
                <div className="ws00">
                  <iframe src="https://the-spicery.web.app/" title="Website in iframe"></iframe>
                </div>
              </div> */}

            </div>  
          </div>;
 };
 
 export default Developer;